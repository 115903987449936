"use client";

import { ArrowLeft } from "lucide-react";
import Link from "next/link";
import { useSession } from "next-auth/react";

import { useStore } from "@hooks/use-store";
import { getMenuURL } from "@utils";

import HeaderAccount from "../header-account";
import HeaderContact from "../header-contact";

const HeaderCompact = ({
  title,
  showArrow = true,
}: {
  title: string;
  showArrow?: boolean;
}): JSX.Element => {
  const session = useSession();
  const { isMobile } = useStore((state) => ({
    isMobile: state.deviceSlice.isMobile,
  }));

  return (
    <header className="mx-auto w-full max-w-screen-md">
      <div className="flex justify-between">
        {showArrow ? (
          <Link href={getMenuURL("app")} className="flex items-center gap-1">
            <ArrowLeft className="h-6 w-6" />
            <h1 className="text-2xl font-semibold">{title}</h1>
          </Link>
        ) : (
          <h1 className="text-2xl font-semibold">{title}</h1>
        )}
        <div className="hidden md:block">
          <HeaderAccount
            user={session?.data?.user}
            isMobile={isMobile}
            size={"large"}
            side={"bottom"}
            className="px-6"
          />
        </div>
        <div className="md:hidden">
          <HeaderAccount
            user={session?.data?.user}
            isMobile={isMobile}
            side={"bottom"}
          />
        </div>
      </div>
      <HeaderContact />
    </header>
  );
};

export default HeaderCompact;
