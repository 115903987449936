"use client";

import { Fragment, HTMLAttributes, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderCircle } from "lucide-react";
import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@components/common/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@components/common/form";
import { Input } from "@components/common/input";
import { ToastAction } from "@components/common/toast";
import { useLocale } from "@hooks/use-locale";
import { useToast } from "@hooks/use-toast";
import { subscribe } from "@services/feedback";

const NotifyMe = ({
  className,
}: {
  className?: HTMLAttributes<HTMLElement>["className"];
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const t = useTranslations("common.notifyMe");
  const tToast = useTranslations("common.toast");
  const locale = useLocale();
  const { toast } = useToast();

  const notifyMeSchema = z.object({
    email: z.string({ message: t("emailRequired") }).email(t("emailInvalid")),
  });

  const form = useForm<z.infer<typeof notifyMeSchema>>({
    resolver: zodResolver(notifyMeSchema),
    defaultValues: { email: "" },
  });

  const showError = (values: z.infer<typeof notifyMeSchema>): void => {
    toast({
      title: tToast("errorTitle"),
      description: tToast("errorDescription"),
      variant: "destructive",
      action: (
        <ToastAction
          altText={tToast("tryAgain")}
          onClick={() => onSubmit(values)}
        >
          {tToast("tryAgain")}
        </ToastAction>
      ),
    });
  };

  const onSubmit = (values: z.infer<typeof notifyMeSchema>): void => {
    setIsLoading(true);
    subscribe({ locale, email: values.email })
      .then(({ error }) => {
        if (error) {
          showError(values);
        } else {
          setIsSubmitted(true);
        }
      })
      .catch(() => {
        showError(values);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      {!isSubmitted && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={className}>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder={t("emailPlaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="px-5" type="submit" disabled={isLoading}>
              {isLoading && <LoaderCircle className="mr-1 animate-spin" />}
              {t("submit")}
            </Button>
          </form>
        </Form>
      )}
      {isSubmitted && (
        <p className="text-center font-medium">{t("thankYou")}</p>
      )}
    </Fragment>
  );
};

export default NotifyMe;
